<template>
    <popper
        trigger="hover"
        placement="top-start"
        @open="handleOpen"
        @close="handleClose"
        :trigger-class="{
            'cursor-pointer': true,
            'bg-black/5': open,
        }"
        :show-group="`${resourceName}-${resourceId}-peek`"
    >
        <template #trigger>
            <resource-link
                @click.stop
                :resource-id="resourceId"
                :resource-name="resourceName"
                :resource-title="resourceTitle"
            />
        </template>

        <template #content>
            <div class="shadow md:rounded-lg">
                <div
                    v-if="loading"
                    class="bg-white dark:bg-gray-900 text-gray-500 dark:text-gray-400 rounded-lg p-3"
                >
                    <component-loader width="30" />
                </div>

                <div
                    v-else
                    class="min-w-[12rem] md:min-w-[24rem] max-w-2xl bg-white dark:bg-gray-900"
                >
                    <a
                        class="link-default bg-gray-700 text-white border border-b-0 border-gray-300 py-1 px-3 rounded-t-lg hidden md:block"
                        :title="__('View')"
                        :href="resourceUrl"
                    >
                        {{ resourceInformation.singularLabel }}:
                        {{ resourceTitle }}
                    </a>

                    <field-grid
                        v-if="resourceFields.length > 0"
                        class="@container/peekable"
                    >
                        <component
                            v-for="(field, index) in resourceFields"
                            :key="index"
                            :index="index"
                            :is="`detail-${field.component}`"
                            :resource-name="resourceName"
                            :resource-id="resourceId"
                            :field="field"
                        />
                    </field-grid>

                    <p
                        v-else
                        class="p-3 text-center dark:text-gray-400"
                    >
                        {{ __("There's nothing configured to show here.") }}
                    </p>
                </div>
            </div>
        </template>
    </popper>
</template>

<script setup>
    import { computed, ref } from "vue";
    import once from "lodash/once";
    import Popper from "@nova/components/Popper.vue";
    import ResourceLink from "@nova/components/ResourceLink.vue";
    import ComponentLoader from "@nova/components/Icons/ComponentLoader.vue";
    import FieldGrid from "@nova/components/FieldGrid.vue";

    const loading = ref(true);
    const resourceFields = ref(null);
    const open = ref(false);

    const handleOpen = function () {
        open.value = true;
        fetchOnce();
    };

    const handleClose = function () {
        open.value = false;
    };

    const fetchOnce = once(() => fetch());

    const props = defineProps(["resourceName", "resourceId", "resourceTitle"]);

    const resourceInformation = computed(() =>
        Nova.resourceInformation(props.resourceName),
    );

    const resourceUrl = computed(() =>
        resourceInformation.value.routeToDetail
            ? resourceInformation.value.routeToDetail.replace(
                  ":id",
                  props.resourceId,
              )
            : Nova.url(`/resources/${props.resourceName}/${props.resourceId}`),
    );

    async function fetch() {
        loading.value = true;

        try {
            resourceFields.value = await Nova.store.dispatch(
                `${props.resourceName}/peek`,
                {
                    resourceName: props.resourceName,
                    resourceId: props.resourceId,
                },
            );
        } finally {
            loading.value = false;
        }
    }
</script>
