<template>
    <tfoot v-if="grandTotal || loading">
        <tr class="group border-l border-r last:border-b border-gray-300">
            <template v-if="!loading">
                <td v-if="shouldShowCheckboxes"></td>
                <td
                    v-for="field in grandTotal.fields"
                    :key="field.uniqueKey"
                    :class="{
                        'whitespace-nowrap': !field.wrapping,
                    }"
                >
                    <component
                        :is="'index-' + field.component"
                        :class="`text-${field.textAlign}`"
                        :field="field"
                        :resource="grandTotal"
                        :resource-name="resourceName"
                        :via-resource="viaResource"
                        :via-resource-id="viaResourceId"
                    />
                </td>
                <td v-if="shouldShowActions"></td>
            </template>
            <td
                v-else
                colspan="100"
            >
                <div class="flex items-center h-4">
                    <component-loader />
                </div>
            </td>
        </tr>
    </tfoot>
</template>

<script>
    export default {
        inject: [
            "resourceInformation",
            "resourceName",
            "shouldShowCheckboxes",
            "viaRelationship",
            "viaResource",
            "viaResourceId",
        ],

        props: ["grandTotal", "loading", "shouldShowActions"],
    };
</script>
