<template>
    <div
        :class="fieldWrapperClasses"
        :dusk="field.attribute"
    >
        <div :class="labelWrapperClasses">
            <slot name="label">
                <form-label
                    :label="field.name"
                    :sublabel="field.sublabel"
                />
            </slot>
            <div class="ml-auto relative flex items-center gap-1">
                <help-text-tooltip
                    v-if="field.helpText"
                    :text="field.helpText"
                />
                <copy-button
                    v-if="fieldValue && field.copyable && !field.asHtml"
                    @click.prevent.stop="copy"
                />
                <slot name="actions" />
            </div>
        </div>
        <div :class="controlWrapperClasses">
            <slot
                name="value"
                :item="this"
            >
                <div
                    v-if="stacked && !field.asHtml"
                    class="form-control"
                    data-disabled
                >
                    <slot
                        name="field-value"
                        :item="this"
                    >
                        {{ fieldValue }}
                    </slot>
                </div>
                <p
                    v-else-if="fieldValue && !field.asHtml"
                    class="flex items-center"
                >
                    <slot
                        name="field-value"
                        :item="this"
                    >
                        {{ fieldValue }}
                    </slot>
                </p>
                <div
                    v-else-if="fieldValue && field.asHtml"
                    v-html="fieldValue"
                />
                <p v-else-if="!stacked">&mdash;</p>
            </slot>
        </div>
    </div>
</template>

<script>
    import DisplayField from "@nova/extendables/DisplayField.vue";
    import { gridSpan } from "@nova/utils";
    import HelpTextTooltip from "@nova/components/HelpTextTooltip.vue";
    import CopyButton from "@nova/components/Buttons/CopyButton.vue";

    export default {
        extends: DisplayField,

        components: {
            HelpTextTooltip,
            CopyButton,
        },

        computed: {
            fieldWrapperClasses() {
                return {
                    "panel-item group/item": true,
                    "stacked:panel-item": this.stacked,
                    [this.fieldWidthClass]: true,
                };
            },

            labelWrapperClasses() {
                return [
                    "label-wrapper",
                    "@sm/peekable:group-last/item:rounded-bl-lg",
                ];
            },

            controlWrapperClasses() {
                return [
                    "control-wrapper",
                    "@sm/peekable:group-last/item:rounded-br-lg",
                ];
            },

            fieldWidthClass() {
                return gridSpan(this.field.width);
            },

            stacked() {
                return this.field.stacked === false ? false : true;
            },

            label() {
                return this.field.name;
            },
        },
    };
</script>

<style>
    @layer nova {
        /* Base */
        .panel-item {
            .label-wrapper {
                @apply flex items-center gap-x-1;
            }

            .control-wrapper {
                @apply bg-white dark:bg-gray-900;
                @apply flex items-center w-full relative;
            }
        }

        /* Default */
        .panel-item:not(.stacked\:panel-item) {
            @apply flex flex-col md:flex-row;
            @apply border-gray-400 border-b dark:border-gray-700;

            .label-wrapper {
                @apply bg-gray-200 px-3 dark:bg-gray-800 w-full md:w-1/4 py-1 md:py-2;
            }

            .control-wrapper {
                @apply px-3 md:w-3/4 py-1 md:py-3;
            }
        }

        /* Stacked */
        .panel-item.stacked\:panel-item {
            .label-wrapper {
                @apply flex;
            }
        }

        /* Peekable */
        .\@container\/peekable {
            .panel-item {
                @apply border-l border-r first:border-t last:rounded-b-lg;

                .label-wrapper {
                    @apply md:min-w-[45%] md:py-2;
                }

                .control-wrapper {
                    @apply md:py-2 text-sm overflow-x-hidden;
                }
            }
        }
    }
</style>
