<template>
    <loading-view
        :loading="initialLoading"
        :dusk="lensKey + '-lens-component'"
        :data-lens="lensKey"
        :data-relationship="viaRelationship"
        :class="{
            'mx-4': !isRelation,
        }"
    >
        <template v-if="shouldOverrideMeta && resourceInformation">
            <app-head
                :title="lensName"
                :section="resourceInformation.group"
            />

            <teleport to="#page-title">
                {{ lensName }}
            </teleport>
        </template>

        <div
            v-else
            class="flex items-center justify-between mb-2"
        >
            <heading
                :level="2"
                dusk="index-heading"
                class="flex items-center"
            >
                {{ lensName }}
            </heading>

            <div :id="`${lensKey}-panel-actions`" />
        </div>

        <teleport
            :to="
                shouldOverrideMeta
                    ? '#page-actions'
                    : `#${lensKey}-panel-actions`
            "
        >
            <div
                class="flex flex-col-reverse lg:flex-row lg:items-center gap-2 lg:divide-x lg:divide-gray-300"
            >
                <div
                    v-if="availableStandaloneRunActions.length > 0"
                    class="ml-auto"
                >
                    <div class="flex flex-wrap items-center gap-2">
                        <action-buttons
                            @actionExecuted="handleActionExecuted"
                            :endpoint="lensActionEndpoint"
                            :resource-name="resourceName"
                            :via-resource="viaResource"
                            :via-resource-id="viaResourceId"
                            :via-relationship="viaRelationship"
                            :relationship-type="relationshipType"
                            :actions="availableStandaloneRunActions"
                            :selected-resources="
                                selectedResourcesForActionSelector
                            "
                        />
                    </div>
                </div>
                <button-group class="lg:pl-2 first:pl-0">
                    <action-buttons
                        v-if="availableStandaloneLinkActions.length > 0"
                        @actionExecuted="handleActionExecuted"
                        :endpoint="lensActionEndpoint"
                        :resource-name="resourceName"
                        :via-resource="viaResource"
                        :via-resource-id="viaResourceId"
                        :via-relationship="viaRelationship"
                        :relationship-type="relationshipType"
                        :actions="availableStandaloneLinkActions"
                        :selected-resources="selectedResourcesForActionSelector"
                    />
                </button-group>
            </div>
        </teleport>

        <card class="grid grid-cols-6">
            <div
                v-show="filterDockLeft"
                class="pr-4 border-r mr-4"
            >
                <field-grid
                    data-dock="left"
                    stacked
                />
            </div>

            <div
                :class="{
                    'col-span-5': filterDockLeft,
                    'col-span-6': !filterDockLeft,
                }"
            >
                <field-grid
                    data-dock="top"
                    stacked
                    class="mb-4"
                />

                <resource-table-toolbar
                    :action-endpoint="lensActionEndpoint"
                    @action-executed="getResources"
                    @clear-selected-filters="clearSelectedFilters(lens || null)"
                    @close-delete-modal="closeDeleteModal"
                    @delete-all-matching="deleteAllMatchingResources"
                    @delete-selected="deleteSelectedResources"
                    @deselect-all="clearResourceSelections"
                    @filter-changed="filterChanged"
                    @filter-dock-changed="filterDockChanged"
                    @force-delete-all-matching="forceDeleteAllMatchingResources"
                    @force-delete-selected="forceDeleteSelectedResources"
                    @per-page-changed="updatePerPageChanged"
                    @polling-toggled="togglePolling"
                    @restore-all-matching="restoreAllMatchingResources"
                    @restore-selected="restoreSelectedResources"
                    @search="search = $event"
                    @select-all-matching="toggleSelectAllMatching"
                    @select-all="toggleSelectAll"
                    @trashed-changed="trashedChanged"
                />

                <loading-view
                    :loading="loading"
                    :variant="!resourceResponse ? 'default' : 'overlay'"
                >
                    <index-error-dialog
                        v-if="resourceResponseError != null"
                        :resource="resourceInformation"
                        @click="getResources"
                    />

                    <index-empty-dialog
                        v-else-if="!resources.length"
                        :create-button-label="createButtonLabel"
                        :resource-label="lensName"
                        :resource-name="resourceName"
                        :via-resource="viaResource"
                        :via-resource-id="viaResourceId"
                        :via-relationship="viaRelationship"
                        :relationship-type="relationshipType"
                        :authorized-to-create="authorizedToCreate"
                        :authorized-to-relate="authorizedToRelate"
                    />

                    <template v-else>
                        <resource-table
                            :authorized-to-relate="authorizedToRelate"
                            :resources="resources"
                            :grand-total="grandTotal"
                            :loading-grand-total="loadingGrandTotal"
                            :singular-name="singularName"
                            :selected-resource-ids="selectedResourceIds"
                            :actions-are-available="actionsAreAvailable"
                            :update-selection-status="updateSelectionStatus"
                            :should-show-actions="shouldShowActions"
                            :sortable="sortable"
                            @order="orderByField"
                            @reset-order-by="resetOrderBy"
                            @delete="deleteResources"
                            @restore="restoreResources"
                            @actionExecuted="handleActionExecuted"
                            ref="resourceTable"
                            class="mb-2"
                        />

                        <resource-pagination
                            v-if="shouldShowPagination"
                            :pagination-component="paginationComponent"
                            :has-next-page="hasNextPage"
                            :has-previous-page="hasPreviousPage"
                            :load-more="loadMore"
                            :loading-more="loadingMore"
                            :select-page="selectPage"
                            :total-pages="totalPages"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :resource-count-label="resourceCountLabel"
                            :current-resource-count="currentResourceCount"
                            :all-matching-resource-count="
                                allMatchingResourceCount
                            "
                        />
                    </template>
                </loading-view>
            </div>
        </card>
    </loading-view>
</template>

<script>
    import ActionButtons from "@nova/components/ActionButtons.vue";
    import AppHead from "@nova/components/AppHead.vue";
    import ButtonGroup from "@nova/components/Buttons/ButtonGroup.vue";
    import Card from "@nova/components/Card.vue";
    import FieldGrid from "@nova/components/FieldGrid.vue";
    import IndexEmptyDialog from "@nova/components/IndexEmptyDialog.vue";
    import IndexErrorDialog from "@nova/components/IndexErrorDialog.vue";
    import LoadingView from "@nova/components/LoadingView.vue";
    import ResourceListingPage from "@nova/extendables/ResourceListingPage.vue";
    import ResourceTableToolbar from "@nova/components/ResourceTableToolbar.vue";
    import { CancelToken, isCancel } from "axios";
    import { mapActions } from "vuex";

    export default {
        extends: ResourceListingPage,

        components: {
            ActionButtons,
            AppHead,
            ButtonGroup,
            Card,
            FieldGrid,
            IndexEmptyDialog,
            IndexErrorDialog,
            LoadingView,
            ResourceListingPage,
            ResourceTableToolbar,
        },

        props: {
            lens: {
                type: Object,
                required: true,
            },
        },

        data: () => ({
            actionCanceller: null,
            resourceHasId: false,
        }),

        beforeUnmount() {
            Nova.$off("refresh-resources", this.getResources);

            if (this.actionCanceller !== null) this.actionCanceller();
        },

        methods: {
            ...mapActions(["fetchPolicies"]),

            getResources() {
                this.loading = true;
                this.resourceResponseError = null;

                this.$nextTick(() => {
                    this.clearResourceSelections();

                    return this.$http
                        .get(
                            `/nova-api/${this.resourceName}/lens/${this.lensKey}`,
                            {
                                params: this.resourceRequestQueryString,
                                cancelToken: new CancelToken((canceller) => {
                                    this.canceller = canceller;
                                }),
                            },
                        )
                        .then(({ data }) => {
                            this.resources = [];

                            this.resourceResponse = data;
                            this.resources = data.resources;
                            this.softDeletes = data.softDeletes;
                            this.perPage = data.per_page;
                            this.resourceHasId = data.hasId;

                            this.handleResourcesLoaded();
                        })
                        .catch((e) => {
                            if (isCancel(e)) {
                                return;
                            }

                            this.loading = false;
                            this.resourceResponseError = e;

                            throw e;
                        });
                });
            },

            getActions() {
                if (this.actionCanceller !== null) this.actionCanceller();

                this.actions = [];
                this.pivotActions = null;

                Nova.request()
                    .get(
                        `/nova-api/${this.resourceName}/lens/${this.lensKey}/actions`,
                        {
                            params: {
                                viaResource: this.viaResource,
                                viaResourceId: this.viaResourceId,
                                viaRelationship: this.viaRelationship,
                                relationshipType: this.relationshipType,
                                display: "index",
                                resources: this.selectAllMatchingChecked
                                    ? "all"
                                    : this.selectedResourceIds,
                            },
                            cancelToken: new CancelToken((canceller) => {
                                this.actionCanceller = canceller;
                            }),
                        },
                    )
                    .then((response) => {
                        this.actions = response.data.actions;
                        this.pivotActions = response.data.pivotActions;
                        this.resourceHasActions =
                            response.data.counts.resource > 0;
                    })
                    .catch((e) => {
                        if (isCancel(e)) {
                            return;
                        }

                        throw e;
                    });
            },

            getAllMatchingResourceCount() {
                Nova.request()
                    .get(
                        "/nova-api/" +
                            this.resourceName +
                            "/lens/" +
                            this.lensKey +
                            "/count",
                        {
                            params: this.resourceRequestQueryString,
                        },
                    )
                    .then((response) => {
                        this.allMatchingResourceCount = response.data.count;
                    });
            },

            getGrandTotal() {
                this.loadingGrandTotal = true;

                if (!this.hasResources) {
                    this.grandTotal = null;
                    this.loadingGrandTotal = false;

                    return;
                }

                Nova.request()
                    .get(
                        `/nova-api/${this.resourceName}/lens/${this.lensKey}/grand-total`,
                        {
                            params: this.resourceRequestQueryString,
                        },
                    )
                    .then((response) => {
                        this.grandTotal = response.data.grandTotal;
                        this.loadingGrandTotal = false;
                    });
            },

            loadMore() {
                if (this.currentPageLoadMore === null) {
                    this.currentPageLoadMore = this.currentPage;
                }

                this.currentPageLoadMore = this.currentPageLoadMore + 1;

                return this.$http
                    .get(
                        "/nova-api/" +
                            this.resourceName +
                            "/lens/" +
                            this.lensKey,
                        {
                            params: {
                                ...this.resourceRequestQueryString,
                                page: this.currentPageLoadMore, // We do this to override whatever page number is in the URL
                            },
                        },
                    )
                    .then(({ data }) => {
                        this.resourceResponse = data;
                        this.resources = [...this.resources, ...data.resources];

                        this.getAllMatchingResourceCount();

                        Nova.$emit("resources-loaded", {
                            resourceName: this.resourceName,
                            lens: this.lensKey,
                            mode: "lens",
                        });
                    });
            },

            getFilterDockTarget(option) {
                return `[data-lens=${this.lensKey}] [data-dock=${option}]`;
            },
        },

        computed: {
            actionQueryString() {
                return {
                    currentSearch: this.currentSearch,
                    encodedFilters: this.encodedFilters,
                    currentTrashed: this.currentTrashed,
                    viaResource: this.viaResource,
                    viaResourceId: this.viaResourceId,
                    viaRelationship: this.viaRelationship,
                };
            },

            actionsAreAvailable() {
                return (
                    this.allActions.length > 0 && Boolean(this.resourceHasId)
                );
            },

            /**
             * Get the endpoint for this resource's actions.
             */
            lensActionEndpoint() {
                return `/nova-api/${this.resourceName}/lens/${this.lensKey}/action`;
            },

            /**
             * Get the endpoint for this resource's metrics.
             */
            cardsEndpoint() {
                return `/nova-api/${this.resourceName}/lens/${this.lensKey}/cards`;
            },

            /**
             * Determine whether the user is authorized to perform actions on the delete menu
             */
            canShowDeleteMenu() {
                return (
                    Boolean(this.resourceHasId) &&
                    Boolean(
                        this.authorizedToDeleteSelectedResources ||
                            this.authorizedToForceDeleteSelectedResources ||
                            this.authorizedToDeleteAnyResources ||
                            this.authorizedToForceDeleteAnyResources ||
                            this.authorizedToRestoreSelectedResources ||
                            this.authorizedToRestoreAnyResources,
                    )
                );
            },

            /**
             * The Lens name.
             */
            lensName() {
                if (this.resourceResponse) {
                    return this.resourceResponse.name;
                }
            },

            lensKey() {
                return this.lens.uriKey;
            },

            withoutPaginationCount() {
                return this.lens.withoutPaginationCount;
            },

            defaultDockOptionStyle() {
                return this.lens.defaultFilterDockStyle;
            },

            searchable() {
                return this.lens.searchable;
            },

            shouldShowActions() {
                return (
                    this.lens.shouldShowActions ??
                    this.resourceInformation.shouldShowActions
                );
            },
        },
    };
</script>
